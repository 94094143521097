<template>
  <div
    class="class-card-wrapper"
    :class="[
      cardClass,
      wrapperBorderClass,
      cardUnavailable ? 'unavailable' : '',
    ]"
    @click.capture="cardClickHandler($event)"
  >
    <!-- 班级卡片右上角标签 -->
    <div
      v-if="showRightTopTag && cardRightTagText"
      class="class-right-top-tag"
      :class="[cardRightTagClass]"
    >
      {{ cardRightTagText }}
    </div>

    <div v-if="closeIcon" class="icon-close-mycourses" />

    <!-- 班级卡片标识戳 -->
    <div
      v-if="showBackStamp && cardStampClass"
      class="class-back-stamp"
      :class="[cardStampClass]"
    />

    <!-- 班级基础信息 -->
    <nuxt-link
      class="class-info-body"
      :class="{ clickable: cardClickable }"
      :to="link"
    >
      <template v-if="isTagFirst">
        <slot name="user-info" />
        <div
          v-if="
            (showLevelDegree && levelDegreeStar) ||
              isShowClassStore ||
              isIncludeTrans
          "
          class="diffcult-store-wrapper module-section"
          :class="{
            hide:
              !(showLevelDegree && levelDegreeStar) &&
              !isShowClassStore &&
              platformTag &&
              !isIncludeTrans,
          }"
        >
          <!-- 难度模块 -->
          <template v-if="showLevelDegree && levelDegreeStar">
            <div class="class-diffcult-container normal-text font-medium">
              <div class="diffcult-content">
                <div class="diffcult-label-text">
                  {{ pageConfig.difficulty }}
                </div>
                <Rate icon-size="14px" :rate-level="levelDegreeStar" />
              </div>
            </div>
          </template>
          <!-- 班级限额模块 -->
          <div
            v-if="isShowClassStore"
            class="class-store-container normal-text font-medium"
          >
            {{
              $tc("courses.classStore", cardData.store, {
                store: cardData.store,
              })
            }}
          </div>
          <!-- <div v-if="subjectTag" class="class-tag-container normal-text font-medium">
              {{ subjectTag }}
            </div> -->
          <!-- <div v-if="platformTag" class="class-tag-container-orange normal-text font-medium">
            {{ platformTag }}
          </div> -->
          <div
            v-if="isIncludeTrans"
            class="class-transfer-container normal-text font-medium"
          >
            {{
              locale === "hk"
                ? transConfig.classTransDiff
                : transConfig.classTrans
            }}
          </div>
        </div>
        <!-- 标题模块 -->
        <div
          class="class-title-container tag-first module-section"
          :class="[
            showRightTopTag && cardRightTagText ? 'title-top-space' : '',
            locale === 'hk' ? 'class-title-container-hk' : '',
          ]"
        >
          <div class="class-title">
            <div class="title-name font-semibold">
              <span v-if="cardData.tag" class="tag-new">{{ cardData.tag }}</span><slot name="class-title">
                {{ cardData.title }}
              </slot>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <slot name="user-info" />
        <!-- 标题模块 -->
        <div
          class="class-title-container module-section"
          :class="[
            showRightTopTag && cardRightTagText ? 'title-top-space' : '',
            locale === 'hk' ? 'class-title-container-hk' : '',
          ]"
        >
          <div class="class-title">
            <div class="title-name font-semibold">
              <span v-if="subjectTag" class="tag-new">{{ subjectTag }}</span><slot name="class-title">
                {{ cardData.title }}
              </slot>
            </div>
          </div>
        </div>

        <!-- 存疑存疑 -->

        <div
          v-if="
            (showLevelDegree && levelDegreeStar) ||
              isShowClassStore ||
              isIncludeTrans
          "
          class="diffcult-store-wrapper module-section"
          :class="{
            hide:
              !(showLevelDegree && levelDegreeStar) &&
              !isShowClassStore &&
              platformTag &&
              !isIncludeTrans,
          }"
        >
          <!-- 难度模块 -->
          <template v-if="showLevelDegree && levelDegreeStar">
            <div class="class-diffcult-container normal-text font-medium">
              <div class="diffcult-content">
                <div class="diffcult-label-text">
                  {{ pageConfig.difficulty }}
                </div>
                <Rate icon-size="14px" :rate-level="levelDegreeStar" />
              </div>
            </div>
          </template>
          <!-- 班级限额模块 -->
          <div
            v-if="isShowClassStore"
            class="class-store-container normal-text font-medium"
          >
            {{
              $tc("courses.classStore", cardData.store, {
                store: cardData.store,
              })
            }}
          </div>
          <!-- <div v-if="subjectTag" class="class-tag-container normal-text font-medium">
              {{ subjectTag }}
            </div> -->
          <!-- <div v-if="platformTag" class="class-tag-container normal-text font-medium">
            {{ platformTag }}
          </div> -->
          <div
            v-if="isIncludeTrans"
            class="class-transfer-container normal-text font-medium"
          >
            {{
              locale === "hk"
                ? transConfig.classTransDiff
                : transConfig.classTrans
            }}
          </div>
        </div>
      </template>

      <!-- 时间信息模块 -->
      <div
        v-if="showDate && dateFormat"
        class="class-date-container module-section font-medium"
      >
        <div
          class="class-date-time class-date normal-text text-overflow-ellipsis"
        >
          <i class="iconfont icon-take-class-time" /><span><slot name="start-date">{{
            startDate ||
              (cardData.spec && cardData.spec.startDate)
              | resourceDateFormate(timezone, dateFormat)
          }}</slot></span><span v-if="!isSameDay" class="line">-</span><span v-if="!isSameDay" class="date-text"><slot name="end-date">{{
            endDate ||
              (cardData.spec && cardData.spec.endDate)
              | resourceDateFormate(timezone, dateFormat)
          }}</slot></span>
        </div>
        <div
          v-if="showTimeDesc && classTimeDesc"
          class="class-date-time class-time-desc normal-text text-overflow-ellipsis"
        >
          <i class="iconfont icon-clock-two" /><span><slot name="time-desc">{{ classTimeDesc }}</slot></span>
        </div>
      </div>

      <!-- 面授地址， 香港分校面授课程显示 -->
      <div
        v-if="isShowAddress"
        class="class-location-container module-section font-medium text-overflow-ellipsis normal-text"
      >
        <i class="iconfont icon-location" /><span>{{
          getAddress || $t("courses.noAddress")
        }}</span>
      </div>

      <!-- 聯繫電話 -->
      <div
        v-if="isShowPhone"
        class="class-contact-container module-section font-medium normal-text"
      >
        <i class="iconfont icon-telephone" /><span class="lesson-value-text">{{
          getPhone
        }}</span>
      </div>

      <!-- 讲次数模块 -->
      <div
        v-if="isShowLessonNum"
        class="class-lesson-num-container normal-text module-section font-medium"
      >
        <i class="iconfont icon-session" /><span>{{ classNumText }}</span><span class="lesson-value-text"><slot name="lesson-text">{{ lessonText }}</slot></span>
      </div>

      <!-- 教师信息模块-头像信息 -->
      <template v-if="showTeacherInfo">
        <slot name="teachers-item">
          <template v-if="allTeacherList.length > 0">
            <!-- 教师数量小于等于2 -->
            <template v-if="allTeacherList.length <= 2">
              <div class="class-teacher-container module-section">
                <div
                  v-for="(teacher, index) in allTeacherList"
                  :key="index"
                  class="teacher-item-container"
                >
                  <div class="teacher-avatar">
                    <img
                      v-lazy-load
                      class="teacher-avatar-img"
                      width="38px"
                      v-lazy-load :data-src="`${teacher.avatar}?im=Resize=(100,100)`"
                      alt="teacher-avatar"
                    >
                  </div>
                  <div class="teacher-title-info font-medium">
                    <div class="teacher-name text-overflow-ellipsis">
                      {{ teacher.sysName }}
                    </div>
                    <div class="teacher-title text-overflow-ellipsis">
                      {{ getTeacherType(teacher.teacherType) }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <!-- 教师数量大于2 -->
            <template v-else>
              <div class="class-teacher-container only-avatar">
                <template v-for="(teacher, index) in allTeacherList">
                  <div
                    v-if="index <= 5"
                    :key="index"
                    class="teacher-avatar"
                    :style="{ zIndex: allTeacherList.length - index }"
                  >
                    <img
                      v-lazy-load
                      class="teacher-avatar-img"
                      width="38px"
                      v-lazy-load :data-src="`${teacher.avatar}?im=Resize=(100,100)`"
                      alt="teacher-avatar"
                    >
                  </div>
                </template>
                <div v-if="allTeacherList.length >= 5" class="teacher-more">
                  <div class="icon-more" />
                </div>
              </div>
            </template>
          </template>
        </slot>
      </template>
    </nuxt-link>

    <slot name="card-body-custom-slot" />

    <slot name="card-bottom">
      <div v-if="showCardBottom" class="card-bottom-wrapper">
        <slot name="card-bottom-body">
          <slot name="card-bottom-left">
            <template v-if="showClassStarted && totalLessons > leftLessons">
              <div class="started-lessons-tag">
                {{
                  $t("courses.lessonStarted", [
                    totalLessons - leftLessons,
                    totalLessons - leftLessons > 1 ? "s" : "",
                  ])
                }}
              </div>
            </template>
          </slot>

          <template v-if="showPriceInfo">
            <div
              class="price-container font-semibold"
              :class="{ 'group-buy-activity': isGroupBuy }"
            >
              <div
                v-if="isGroupBuy && groupBuyStyle"
                class="group-buy"
                @click="handleGroupBuyClick"
              >
                {{ $t("featureComponents.ClassCard.groupBuy") }}
              </div>
              <!-- 免费价 -->
              <template v-if="isClassFree">
                <span
                  v-if="locale === 'sg' && cardData.showOrgPrice !== 0"
                  class="discount-price"
                >
                  {{ currency
                  }}{{
                    (showPerLesson
                      ? cardData.perOrgPrice
                      : cardData.showOrgPrice)
                      | priceUnitConvert(minorUnit, isKeepTwoDecimal)
                  }}
                </span>
                <span class="free-price">{{ $t("coursesDetail.free") }}</span>
              </template>
              <template v-else>
                <div class="price-wrap">
                  <!-- 活动价 -->
                  <template v-if="isGroupBuy">
                    <div class="group-price">
                      <span class="discount-price">{{ currency
                      }}{{
                        showPrice
                          | priceUnitConvert(minorUnit, isKeepTwoDecimal)
                      }}</span>
                      <!-- eslint-disable-next-line max-len -->
                      <span class="sell-price">{{ currency
                                               }}{{
                                                 (showPerLesson
                                                   ? cardData.activityItem.perShowPrice
                                                   : cardData.activityItem.showPrice)
                                                   | priceUnitConvert(minorUnit, isKeepTwoDecimal)
                                               }}
                        <span v-if="showPerLesson" class="per-lesson-text">{{
                          $t("courses.perLesson")
                        }}</span></span>
                    </div>
                  </template>
                  <!-- 普通价格展示 -->
                  <template v-else>
                    <div>
                      <span
                        v-if="showOriginalPrice && isShowOriginalPrice"
                        class="discount-price"
                      >
                        {{ currency
                        }}{{
                          showOrgPrice
                            | priceUnitConvert(minorUnit, isKeepTwoDecimal)
                        }}
                      </span>
                      <span class="sell-price">
                        {{ currency
                        }}{{
                          showPrice
                            | priceUnitConvert(minorUnit, isKeepTwoDecimal)
                        }}
                        <span v-if="showPerLesson" class="per-lesson-text">{{
                          $t("courses.perLesson")
                        }}</span>
                      </span>
                    </div>
                  </template>
                  <div>
                    <!-- 价格展示是否含税 -->
                    <span
                      v-if="
                        (locale === 'sg' || locale === 'uk') &&
                          showTaxationDesc &&
                          !isGroupBuy
                      "
                      class="price-tag tax-desc-tag font-semibold"
                    >
                      <template v-if="showPriceIsInclude">
                        {{ $t("common.taxExcluded") }}
                      </template>
                      <template v-else>
                        {{ $t("common.taxIncluded") }}
                      </template>
                    </span>
                    <!-- 价格包含课次数 -->
                    <span
                      v-if="locale === 'hk' && showLessonNum"
                      class="price-tag sessions-tag font-semibold"
                    >
                      {{ $t("courses.lessonUnitTxt", [leftLessons]) }}
                    </span>
                  </div>
                </div>
              </template>
            </div>

            <!-- 列表页展示加购 -->
            <template v-if="isCoursesList">
              <div class="btns">
                <AddCart :sku-id="cardData.id" :order-info="cardData" :btn-disabled="btnDisabled">
                  <div :class="{left:true,disabled:btnDisabled}">
                    {{ orderConfig.shoppingCart.addToCart }}
                  </div>
                </AddCart>
                <Enroll :order-info="cardData" :btn-disabled="enrollDisabled">
                  <div :class="{right:true,disabled:enrollDisabled}">
                    Enroll
                  </div>
                </Enroll>
              </div>
            </template>
          </template>

          <!-- 底部栏右侧操作按钮 -->
          <slot name="card-bottom-right">
            <template v-if="showCardBottomRight && bottomRightBtnText">
              <div class="operate-btn-container">
                <button
                  class="button button-small button-right-operate"
                  :class="bottomRightBtnClass"
                  :disabled="bottomRightBtnDisabled"
                  @click="handleBottomRightBtnClick()"
                >
                  {{ bottomRightBtnText }}
                </button>
              </div>
            </template>
          </slot>
        </slot>
      </div>
    </slot>
  </div>
</template>

<script>
import get from 'lodash/get';
import Rate from 'components/common/Rate';
import { isOffline } from 'common/platform.js';
import AddCart from 'components/cart/addCart/index.vue';
import Enroll from 'components/cart/enroll/index.vue';

// mixins
import coursesSa from 'mixins/courses/sa.js';
import classCard from 'mixins/courses/classCard.js';

export default {
  components: { Rate, AddCart, Enroll },
  mixins: [classCard, coursesSa],
  props: {
    isCoursesList: {
      default: false,
      type: Boolean,
    },
    isTagFirst: {
      default: false,
      type: Boolean,
    },
    // 班级卡片信息
    cardData: {
      default: () => ({}),
      type: Object,
    },
    // 卡片是否可点击
    cardClickable: {
      default: true,
      type: Boolean,
    },
    // 卡片点击跳转链接
    cardLink: {
      default: '',
      type: [String, Object],
    },
    // 卡片跳转链接是否新开窗口
    cardLinkNewWindow: {
      default: false,
      type: Boolean,
    },
    // 班级卡片类名
    cardClass: {
      default: '',
      type: String,
    },
    // 卡片标识类型
    tagType: {
      default: 0,
      type: Number,
    },
    // 是否显示右上角标签
    showRightTopTag: {
      default: false,
      type: Boolean,
    },
    // 是否显示标识戳
    showBackStamp: {
      default: false,
      type: Boolean,
    },
    showTagInfo: {
      default: true,
      type: Boolean,
    },
    // 是否展示时间
    showDate: {
      default: true,
      type: Boolean,
    },
    // 开始日期
    startDate: {
      default: '',
      type: String,
    },
    // 结束日期
    endDate: {
      default: '',
      type: String,
    },
    // 是否展示时间描述
    showTimeDesc: {
      default: true,
      type: Boolean,
    },
    // 是否展示难度
    showLevelDegree: {
      default: true,
      type: Boolean,
    },
    // 是否展示课次数
    showLessonNum: {
      default: true,
      type: Boolean,
    },
    // 是否展示聯繫電話
    showVenuePhone: {
      default: false,
      type: Boolean,
    },
    // 总讲次数
    totalLessons: {
      default: 0,
      type: Number,
    },
    // 剩余讲次数
    leftLessons: {
      default: 0,
      type: Number,
    },
    // 是否优先展示总讲次数
    priorityLeftLessons: {
      default: true,
      type: Boolean,
    },
    // 是否显示教师信息
    showTeacherInfo: {
      default: true,
      type: Boolean,
    },
    // 是否展示商品价格信息
    showPriceInfo: {
      default: true,
      type: Boolean,
    },
    // 是否展示商品原价，即划线价
    showOriginalPrice: {
      default: false,
      type: Boolean,
    },
    showPerLesson: {
      default: false,
      type: Boolean,
    },
    // 是否展示税费说明
    showTaxationDesc: {
      default: false,
      type: Boolean,
    },
    // 是否含税
    showPriceIsInclude: {
      default: false,
      type: Boolean,
    },
    // 是否显示卡片底部操作栏
    showCardBottom: {
      default: true,
      type: Boolean,
    },
    // 是否是团购
    groupBuy: {
      default: () => ({}),
      type: Object,
    },
    // 团购样式
    groupBuyStyle: {
      default: true,
      type: Boolean,
    },
    // 是否显示左侧模块
    showCardBottomLeft: {
      default: false,
      type: Boolean,
    },
    // 是否展示班级已开课标识
    showClassStarted: {
      default: false,
      type: Boolean,
    },
    // 是否显示右侧模块
    showCardBottomRight: {
      default: false,
      type: Boolean,
    },
    // 卡片底部右侧按钮文案
    bottomRightBtnText: {
      default: '',
      type: String,
    },
    // 卡片底部右侧按钮是否不可用
    bottomRightBtnDisabled: {
      default: false,
      type: Boolean,
    },
    // 卡片底部右侧按钮样式类
    bottomRightBtnClass: {
      default: () => [],
      type: Array,
    },
    // 卡片曝光埋点事件名称(默认无曝光埋点)
    cardShowLpEventName: {
      default: '',
      type: String,
    },
    // 是否显示上课地址
    showAddressDetail: {
      default: false,
      type: Boolean,
    },
    // 1,"Dual Online" 2,"Dual Offline" 3,"In-Person" 4,"Big_Online" 5,"Trial Class" 6,"Zoom" 7,"Class In"
    platformType: {
      default: '',
      type: String,
    },
    // 0大班 1小班
    subPlatformType: {
      default: 0,
      type: Number,
    },
    // 是否显示班级限额
    showClassStore: {
      default: true,
      type: Boolean,
    },
    // 是否显示外层边框
    showWrapperBorder: {
      default: false,
      type: Boolean,
    },
    // 班级卡片来源模式
    // card/list
    coursesMode: {
      default: 'none',
      type: String,
    },
    // 订单类型
    isIncludeTrans: {
      default: false,
      type: Boolean,
    },
    closeIcon: {
      default: false,
      type: Boolean,
    },
    studentId: {
      default: '',
      type: String,
    },
    isUnionUnpaidCourse: {
      default: false,
      type: Boolean,
    },
    unionGoodsList: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      orderConfig: this.$t('order'),
      clientOptions: null,
      dateFormat: '',
      isKeepTwoDecimal: this.$t('common.isKeepTwoDecimal'),
      pageConfig: this.$t('page.classCard'),
      transConfig: this.$t('featureComponents.OrderDetailTemplate'),
    };
  },
  computed: {
    isLogin() {
      return this.$store.state.ucenter.isLogin
    },
    btnDisabled() {
      return !['forSale', 'enrolNow', 'enrol'].includes(this.btnStatus);
    },
    enrollDisabled() {
      return !['enrolNow', 'enrol'].includes(this.btnStatus);
    },
    // 是否满班状态
    btnStatus() {
      const { cardData } = this
      const {
        spec: { courseClosed }, sellStore, store, remainSellTime,
      } = cardData
      if (courseClosed) {
        return this.formPage === 'unpaid-courses-list' ? 'unavailable' : 'courseEnded'
      }
      if (remainSellTime > 1000) {
        return 'forSale'
      }
      // 待缴费清单不存在报满状态
      if (store - sellStore <= 0 && this.fromPage !== 'unpaid-courses-list') {
        return 'full'
      }
      if (this.fromPage === 'unpaid-courses-list') {
        return 'enrolNow'
      }
      return 'enrol'
    },
    // 边层边框样式名
    wrapperBorderClass() {
      return this.showWrapperBorder ? 'card-border' : '';
    },
    getAddress() {
      if (this.showAddressDetail) {
        return get(
          this.cardData,
          'spec.platformContent.classRoom.venueAddress',
          get(this.cardData, 'platformContent.classRoom.venueAddress'),
        );
      }
      return get(
        this.cardData,
        'spec.platformContent.classRoom.venueName',
        get(this.cardData, 'platformContent.classRoom.venueName'),
      );
    },

    getPhone() {
      return get(
        this.cardData,
        'spec.platformContent.classRoom.venuePhone',
        get(this.cardData, 'platformContent.classRoom.venuePhone'),
      );
    },
    getPlatform() {
      let { platformType, subPlatformType } = this;
      // 不传platformType 时，从cardData取值
      if (!platformType) {
        platformType = get(
          this.cardData,
          'spec.platformType',
          this.cardData.platformType,
        );
      }
      if (!subPlatformType) {
        subPlatformType = get(
          this.cardData,
          'spec.subPlatformType',
          this.cardData.subPlatformType,
        );
      }
      return { platformType, subPlatformType };
    },
    isShowAddress() {
      const { platformType } = this.getPlatform;
      return (
        ['hk', 'us'].includes(this.locale)
        && isOffline(platformType)
        && this.getAddress !== undefined
      );
    },
    isShowPhone() {
      const { platformType } = this.getPlatform;
      return (
        this.showVenuePhone
        && ['hk', 'us'].includes(this.locale)
        && isOffline(platformType)
      );
    },
    isShowLessonNum() {
      return this.showLessonNum && !this.isShowAddress;
    },
    classNumText() {
      const { platformType } = this.getPlatform;
      return isOffline(platformType)
        ? this.pageConfig.inPerson
        : this.pageConfig.liveClass;
    },
    isGroupBuy() {
      return Object.keys(this.groupBuy).length > 0;
    },
    allTeacherList() {
      const { teachers } = this.cardData;
      // 会员中心我的订单、我的课程接口数据兼容
      if (teachers) {
        return teachers.map((item) => ({
          avatar: item.avatar,
          teacherType: item.identityType,
          sysName: item.name,
        }));
      }
      if (!this.cardData.spec) return [];
      const { tutorList = [], teacherList = [] } = this.cardData.spec || {};
      return teacherList.concat(tutorList);
    },
    locale() {
      return this.$store.state.locale;
    },
    timezone() {
      return this.$store.state.timezone;
    },
    currency() {
      return this.$store.state.currency.symbol;
    },
    minorUnit() {
      return this.$store.state.currency.minorUnit;
    },
    isSameDay() {
      if (this.startDate && this.endDate) {
        return (
          this.startDate.split(' ')[0] === String(this.endDate.split(' ')[0])
        );
      }
      return (
        this.cardData.spec
        && String(this.cardData.spec.startDate.split(' ')[0])
          === this.cardData.spec
        && String(this.cardData.spec.endDate.split(' ')[0])
      );
    },
    lessonText() {
      if (this.priorityLeftLessons) {
        // 优先展示剩余课次时
        if (Number(this.leftLessons) === 0) return this.$t('courses.courseEnded');
        return this.$t('courses.lessonLeft', [
          this.totalLessons - this.leftLessons > 0
            ? this.$t('courses.leftTxt')
            : '',
          this.leftLessons,
          this.leftLessons > 1
          && this.locale !== 'hk'
          && this.locale !== 'jp'
          && this.locale !== 'kr'
            ? 's'
            : '',
        ]);
      }
      // 优先展示总讲次时
      if (!this.totalLessons) return '';
      return `${this.totalLessons} ${this.$t('courses.lessonUnit')}${
        this.totalLessons > 1
        && this.locale !== 'hk'
        && this.locale !== 'jp'
        && this.locale !== 'kr'
          ? 's'
          : ''
      }`;
    },
    link() {
      if (!this.cardClickable) return '';
      if (this.cardLink) return this.cardLink;
      if (this.cardData.id) {
        return `/courses/detail/${this.cardData.id || ''}${
          this.studentId ? `?studentId=${this.studentId}` : ''
        }`;
      }
      return '';
    },
    // 是否显示班级限额字段
    isShowClassStore() {
      return this.showClassStore && this.cardData && this.cardData.store > 0;
    },
    // 价格判断逻辑
    isClassFree() {
      // eslint-disable-next-line eqeqeq
      return this.cardData.showPrice == 0;
    },
    isShowOriginalPrice() {
      let diffVal = this.cardData.showOrgPrice - this.cardData.showPrice;
      if (Number.isNaN(diffVal)) diffVal = this.cardData.orgPrice - this.cardData.sellPrice;
      if (Number.isNaN(diffVal)) return false;
      return diffVal > 0;
    },
    showOrgPrice() {
      if (this.showPerLesson) {
        return (
          this.cardData.perOrgPrice
          || this.cardData.showOrgPrice
          || this.cardData.orgPrice
        );
      }
      return this.cardData.showOrgPrice || this.cardData.orgPrice;
    },
    showPrice() {
      if (this.showPerLesson) {
        return (
          this.cardData.perShowPrice
          || this.cardData.showPrice
          || this.cardData.sellPrice
        );
      }
      return this.cardData.showPrice || this.cardData.sellPrice;
    },
    // 难度星级
    levelDegreeStar() {
      const { spec = {} } = this.cardData;
      return spec.levelDegreeStar || this.cardData.levelDegreeStar || 0;
    },
  },
  async mounted() {
    this.classCardShow();
    const clientOptions = JSON.parse(
      window.localStorage.getItem('clientOptions'),
    );
    if (!clientOptions) {
      await this.$store.dispatch('ucenter/getClientOptions', {
        $axios: this.$axios,
      });
    }
    this.clientOptions = JSON.parse(
      window.localStorage.getItem('clientOptions'),
    );
    const dateFormatMap = {
      UK: 'DD/MM/YYYY',
      FR: 'DD/MM/YYYY',
      JP: 'YYYY/MM/DD',
    };
    this.dateFormat = dateFormatMap[this.clientOptions.dateFormat] || 'MM/DD/YYYY';
  },
  methods: {
    goDetail() {
      if (this.enrollDisabled) {
        return
      }
      this.$router.push(this.link)
    },
    getTeacherType(type) {
      if (Number(type) === 1) {
        return this.$store.state.teacher.lecturerTitle;
      }
      return this.$store.state.teacher.assistantTitle;
    },
    // 卡片底部右侧按钮点击事件
    handleBottomRightBtnClick() {
      this.$emit('card-bottom-right-click');
    },
    // 点击卡片跳转至班级详情页
    cardClickHandler(e) {
      if (!this.cardClickable) {
        e.preventDefault();
        return;
      }
      // 判断是否新开窗口
      if (this.cardLinkNewWindow) {
        window.open(this.link);
        e.preventDefault();
      }
      this.classCardClick();
      this.$emit('card-click');
    },
    // 神策埋点-班级卡片曝光
    classCardShow() {
      const fromSourcePage = this.getClassFrom(this.$route.name);
      if (fromSourcePage) {
        this.Sensors.track(
          'class_card_show',
          this.getClassSaData(fromSourcePage, this.cardData, this.coursesMode),
        );
      }
    },
    // 神策埋点-班级卡片点击
    classCardClick() {
      const fromSourcePage = this.getClassFrom(this.$route.name);
      if (fromSourcePage) {
        this.Sensors.track(
          'class_card_click',
          this.getClassSaData(fromSourcePage, this.cardData, this.coursesMode),
        );
      }
    },
    // 设置班级卡片埋点数据
    setClassCardLpParams(cardData) {
      const params = {
        subject_id: cardData.spec.subjectId || '',
        grade_id: cardData.spec.grade || '',
        course_type: cardData.spec.courseType || '',
        term_id: cardData.spec.term || '',
        main_teacher_id: cardData.spec.teacherIds.join() || '',
        sell_price: cardData.sellPrice || '',
        original_price: cardData.orgPrice || '',
        currency_code: cardData.spec.subjectId || '',
      };
      return params;
    },
    handleGroupBuyClick() {
      this.$emit('group-buy-click');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/css/courses.scss";

.btns {
  width: 200px;
  height: 36px;
  display: flex;
  cursor: pointer;

  .left,
  .right {
    text-align: center;
    width: 100px;
    height: 36px;
    line-height: 36px;
    font-size: 12px;
    font-family: Montserrat-Bold, Montserrat, sans-serif;
    font-weight: bold;
  }

  .left {
    box-sizing: border-box;
    background: #fff;
    color: #ff9f0a;
    border: 1px solid #ff9f0a;
    border-radius: 8px 0 0 8px;

    &:hover {
      background: rgba(255, 159, 10, 0.2);
    }

    &.disabled {
      color: #ffebce;
      border: 1px solid #ffebce;
      cursor: no-drop;
      background: #fff;
    }
  }

  .right {
    background: #ff9f0a;
    border-radius: 0 8px 8px 0;
    color: #fff;

    &:hover {
      background: #e58e08;
    }

    &.disabled,
    &.disabled a {
      background: #ffebce;
      cursor: no-drop;
    }
  }
}

.tag-first {
  padding: 20px 0 0 0;
  height: auto;
}

.diffcult-store-wrapper {
  margin-bottom: 10px;
}

.icon-close-mycourses {
  width: 60px;
  height: 60px;
  position: absolute;
  background: url("~assets/images/my-courses/icon_close.png") 0 0/ 100% auto no-repeat;
  bottom: 0;
  right: 0;
}

.class-info-body {
  pointer-events: none;
  cursor: default;

  /deep/.class-title-container {
    padding-bottom: 10px;
    height: auto;
    // 存疑存疑
    .class-title {
      width: 100%;
    }

    .tag-new {
      height: 20px;
      line-height: 20px;
      padding: 0 4px;
      font-size: 12px;
      font-family: Montserrat-Regular, Montserrat, Arial, Helvetica, sans-serif;
      font-weight: 600;
      color: #fff;
      background: var(--txt-color-link);
      border-radius: 4px;
      display: inline-block;
      vertical-align: top;
      margin: 0 10px 0 0;
    }
  }

  &.clickable {
    pointer-events: auto;
    cursor: pointer;
  }
}

.chat-class-card {
  .class-title-container {
    padding: 25px 12px 16px;
  }

  .diffcult-store-wrapper {
    padding: 0 0 0 20px;

    .class-diffcult-container,
    .class-store-container {
      margin-right: 8px;
    }

    .class-tag-container {
      margin-right: 0;
    }
  }

  .class-date-container {
    padding: 0 12px;
  }

  .class-lesson-num-container {
    padding: 2px 12px;
  }

  .class-teacher-container {
    padding: 13px 12px 16px;

    .teacher-item-container {
      .teacher-avatar {
        width: 35px;
        height: 35px;

        .teacher-avatar-img {
          width: 35px;
        }
      }

      .teacher-title-info {
        .teacher-name {
          margin-bottom: 0;
        }
      }
    }
  }

  .card-bottom-wrapper {
    padding: 15px 12px 16px;

    &::before {
      left: 12px;
      right: 12px;
    }
  }
}
</style>
