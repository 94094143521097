// 引入组件库
import Vue from 'vue';

// ElementUI
// 新增组件后，别忘记去@/assets/css/element-ui.scss中引入对应样式哦，不然组件会裸奔哦~
import Icon from 'element-ui/lib/icon';
import Form from 'element-ui/lib/form';
import FormItem from 'element-ui/lib/form-item';
import Input from 'element-ui/lib/input';
import Select from 'element-ui/lib/select';
import Option from 'element-ui/lib/option';
import Checkbox from 'element-ui/lib/checkbox';
import Button from 'element-ui/lib/button';
import Dialog from 'element-ui/lib/dialog';
import Message from 'element-ui/lib/message';
import Notification from 'element-ui/lib/notification';
import Carousel from 'element-ui/lib/carousel';
import CarouselItem from 'element-ui/lib/carousel-item';
import Rate from 'element-ui/lib/rate'
import Breadcrumb from 'element-ui/lib/breadcrumb'
import BreadcrumbItem from 'element-ui/lib/breadcrumb-item';
import Tabs from 'element-ui/lib/tabs';
import TabPane from 'element-ui/lib/tab-pane'
import Upload from 'element-ui/lib/upload'
import Row from 'element-ui/lib/row'
import Col from 'element-ui/lib/col'
import Collapse from 'element-ui/lib/collapse'
import CollapseItem from 'element-ui/lib/collapse-item'
import Switch from 'element-ui/lib/switch'
import Drawer from 'element-ui/lib/drawer'
import Popover from 'element-ui/lib/popover'
import Dropdown from 'element-ui/lib/dropdown'
import DropdownMenu from 'element-ui/lib/dropdown-menu'
import DropdownItem from 'element-ui/lib/dropdown-item'
import InputNumber from 'element-ui/lib/input-number';
import Slider from 'element-ui/lib/slider'
import Image from 'element-ui/lib/image'
import Tooltip from 'element-ui/lib/tooltip';
import Popconfirm from 'element-ui/lib/popconfirm';

Vue.use(Icon);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Checkbox);
Vue.use(Dialog);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Rate);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Upload);
Vue.use(Row);
Vue.use(Col);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Switch);
Vue.use(Drawer);
Vue.use(Popover);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(InputNumber);
Vue.use(Slider);
Vue.use(Image);
Vue.use(Tooltip);
Vue.use(Popconfirm);
Vue.prototype.$ElMessage = Message;
Vue.prototype.$ElNotify = Notification;
