/**
 * API接口地址配置
 */
const oneOfficialPrefix = '/one-official-api';
const onePrefix = '/one-api';
const onePrefixV2 = '/one-base-api/v2';
const oneAIPrefix = '/one-ai-api';
const overseaPrefix = '/oversea-api';
const headlessPrefix = '/headless-api';
const thirdPrefix = '/third-api';
const zeroPrefix = '/zero-api';
const prefixV3 = '/v3'

// one服务提供的旧官网服务接口
export const officialApi = {
  // 查询首页banner数据
  queryBanner: `${oneOfficialPrefix}/home/banner`,
}

/**
  * 统一对接server端接口
  * 基础数据: /basic
  * 认证中心(用户中心): /ucenter
  * 班课中心: /course
  * 售卖系统: /shop
  * 教师中心: /teacher
  * 特定聚合接口: /aggregate
  * 配置数据  /config
  * 营销系统 /coupon
  * 调转中心 /changeCenter
  * 内容管理 /manage
  */
export const oneApi = {
  // 联报需求
  // ----------------
  // 添加购物车
  addCart: `${onePrefix}/aggregate/goods/stu/tempCart/add`,
  // 购物车列表
  getCartList: `${onePrefix}/shop/goods/stu/tempCart/list`,
  // 删除购物车
  delCart: `${onePrefix}/shop/goods/stu/tempCart/cancel`,
  // 修改数量
  updateAmount: `${onePrefix}/shop/goods/stu/tempCart/updateAmount`,
  // 购物车数量
  getCartAmount: `${onePrefix}/shop/goods/stu/tempCart/amount`,
  // checkv3
  signupCheckv3: `${onePrefix}/aggregate/signup/checkv3`,
  // 根据sku查商品（适配购物车数据结构）
  queryGoodsBySku: `${onePrefix}/shop/goods/stu/tempCart/adapt/queryGoodsBySku`,

  // 基础数据
  // ----------------
  // 学科查询(全量数据)
  querySubjectsAll: `${onePrefix}/basic/client/subject/query`,
  // 学科查询(仅展示数据)
  querySubjects: `${onePrefix}/basic/client/subject/queryWithoutHide`,
  // 年级查询(全量数据)
  queryGradesAll: `${onePrefix}/basic/client/grade/query`,
  // 年级查询(仅展示数据)
  queryGrades: `${onePrefix}/basic/client/grade/queryWithoutHide`,
  // 学期查询
  queryTerms: `${onePrefix}/basic/client/term/query`,
  // 课程类型查询
  queryCourseTypes: `${onePrefix}/basic/client/course/getCourseTypes`,
  // 图片上传
  uploadPublicRead: `${onePrefix}/basic/client/picture/uploadPublicRead`,
  // 难度/班次级别查询
  queryLevelDegree: `${onePrefix}/basic/client/levelDegree/query`,
  // 国家列表查询
  queryCountry: `${onePrefix}/basic/client/country/query`,
  // 根据国家获取州
  getState: `${onePrefix}/i18n/api/address/get`,
  // 获取二级、三级地址
  getAddressSubList: `${onePrefix}/i18n/api/address/subList`,
  // 用户中心
  // ----------------
  // 登录验证码发送
  // sendLoginMobileCode: `${onePrefix}/ucenter/common/verification/send`,
  sendLoginMobileCode: `${prefixV3}/ucenter/common/verification/send`,
  // 验证验证吗发送
  sendLoginMobileCodeCheck: `${onePrefix}/ucenter/common/verification/check`,
  verificationCheck: `${onePrefix}/ucenter/common/verification/check`,
  // 快捷登录
  commonLogin: process.env.clientType === 'web' ? `${onePrefix}/ucenter/account/quick_login` : `${onePrefix}/ucenter/app/account/quick_login`,
  // 登出
  commonLogout: `${onePrefix}/ucenter/account/logout`,
  // 绑定邮箱
  bindEmail: `${onePrefix}/ucenter/account/email/modify`,
  emailModify: `${onePrefix}/ucenter/account/email/modify`,
  // 绑定邮箱与手机 + 跳过验证
  bindEmail2: `${onePrefixV2}/ucenter/basic_info/user/modify`,
  // 添加新学员
  addNewStudent: `${onePrefix}/ucenter/account/associated/add_new`,
  // 替换默认地址
  changeDefaultAddress: `${onePrefix}/ucenter/recipient/default/change`,
  // 默认地址查询
  queryDefaultAddress: `${onePrefix}/ucenter/recipient/default/get`,
  // 保存地址
  saveAddress: `${onePrefix}/ucenter/recipient/update`,
  // 新增地址
  addAddress: `${onePrefix}/ucenter/recipient/add`,
  // 获取地址列表
  getAddressList: `${onePrefix}/ucenter/recipient/list`,
  // 删除地址
  deleteAddress: `${onePrefix}/ucenter/recipient/delete`,
  // 获取用户基础信息
  queryUserBasicInfo: `${onePrefixV2}/ucenter/basic_info/user/get`,
  // 获取用户昵称
  queryUserNickname: `${onePrefix}/ucenter/basic_info/user/nickname/get`,
  // 获取家庭组主账号相关信息
  queryMasterUserInfo: `${onePrefix}/ucenter/basic_info/master_user/get`,
  // 修改用户信息
  modifyUserInfo: `${onePrefixV2}/ucenter/basic_info/user/modify`,
  // 更新用户信息
  fillEmptyInfo: `${onePrefixV2}/ucenter/basic_info/user/fillEmptyInfo`,
  // 获取家长信息
  getParentInfo: `${onePrefixV2}/ucenter/basic_info/parent/get`,
  // 修改家长信息
  modifyParentInfo: `${onePrefixV2}/ucenter/basic_info/parent/modify`,
  // 获取家庭账号列表
  getFamilyStudentsList: `${onePrefix}/ucenter/account/associated/list`,
  // 切换账号
  switchAccount: `${onePrefix}/ucenter/account/switch_login`,
  // 子账号
  subChangeCheck: `${onePrefix}/account/subChange/check`,
  unbindCheck: `${onePrefix}/account/subChange/check`,
  // 待支付清单下单账号校验
  toBePaidCheck: `${onePrefix}/account/toBePaidOrder/check`,
  // 批量解绑
  batchUnbind: `${onePrefix}/ucenter/account/associated/unbind`,
  unbindStudent: `${onePrefix}/ucenter/account/associated/unbind`,
  // 绑定手机
  bindPhone: `${onePrefix}/ucenter/account/phone/modify`,
  phoneModify: `${onePrefix}/ucenter/account/phone/modify`,
  // 校验手机号、邮箱有效性
  contactVerify: `${onePrefix}/ucenter/common/contact/verify`,

  // 班课中心
  // 根据学员ID获取班级列表
  getClassListByStuId: `${onePrefix}/studyCenter/app/getClassList`,
  // 根据courseId返回未满班的sku
  getRecommendClass: `${onePrefix}/shop/goods/api/recommendClass`,
  // 获取将次列表
  getLessonList: `${onePrefix}/studyCenter/getLessonListByClassId`,
  // 获取将次列表
  getGroupLessonList: `${onePrefix}/studyCenter/scheduleList`,
  // 获取上课方式
  getTeachMethod: `${onePrefix}/studyCenter/teachMethod`,
  // 课程详情
  queryCourseDetail: `${onePrefix}/aggregate/goods/details`,
  // 商品售卖状态查询
  getSellStatus: `${onePrefix}/shop/goods/api/sellStatus`,
  // 教师中心
  // ----------------
  // 教师简介数据
  queryTeacherBanner: `${onePrefix}/teacher/basic_info/banner/list`,
  queryfaculty: `${onePrefix}/teacher/basic_info/faculty/list`,
  queryTeacherProfile: `${onePrefix}/teacher/basic_info/profile`,

  // 售卖子系统
  // ----------------
  // 商城首页
  queryMallPage: `${onePrefix}/shop/goods/api/mallPage`,
  // 课程列表页
  queryCourseList: `${onePrefix}/shop/goods/api/clazzSearch`,
  // 获取课程列表筛选
  queryCourseListV3: `${onePrefix}/shop/goods/api/clazzSearch/v3`,
  // 获取筛选项
  queryCourseSelector: `${onePrefix}/shop/goods/api/clazzSearch/selector`,
  // 课程列表页难度
  queryCourseListSelector: `${onePrefix}/shop/goods/api/clazzSearchSelector`,
  //  根据课程id获取班级列表
  queryClazzByCourseId: `${onePrefix}/shop/goods/api/queryClazzByCourseId`,

  // 获取商品详情(未聚合)
  queryGoodDetail: `${onePrefix}/shop/goods/api/detail`,
  // 自动支付更换卡片
  changeCard: `${onePrefix}/shop/order/autoPay/changeCard`,
  // 自动支付更换卡片V2
  changeCardV2: `${onePrefix}/shop/order/autoPay/changeCardV2`,
  // 确认分期使用优惠券
  confirmStageCoupon: `${onePrefix}/shop/order/stage/useCoupon`,
  // 课程详情页添加收藏
  queryAddWish: `${onePrefix}/shop/goods/stu/wish/add`,
  // 课程详情页取消收藏
  queryCancelWish: `${onePrefix}/shop/goods/stu/wish/cancel`,
  // 根据组件类型和参数查询班级列表
  queryGoodsByModule: `${onePrefix}/shop/goods/api/queryGoodsByModule`,
  // 关注按钮检查
  followCheck: `${onePrefix}/shop/goods/stu/follow/check`,
  // 关注按钮检查
  followAdd: `${onePrefix}/shop/goods/stu/follow/add`,
  // 关注按钮检查
  followCancel: `${onePrefix}/shop/goods/stu/follow/cancel`,

  // 收藏列表 group
  queryGroupWishList: `${onePrefix}/shop/goods/stu/wish/group/list`,
  // 收藏列表
  queryWishList: `${onePrefix}/shop/goods/stu/wish/list`,
  // 生成分期列表
  getGenerate: `${onePrefix}/shop/order/stage/generate`,
  // 生成分期列表V2
  getGenerateV2: `${onePrefix}/shop/order/stage/generateV2`,
  // 获取手续费
  getHandlingFee: `${onePrefix}/shop/order/calculate/handlingFee`,
  // 创建订单
  createOrder: `${onePrefix}/shop/order/create`,
  // 支付订单
  payOrder: `${onePrefix}/shop/order/pay`,
  // 自动扣款支付订单
  autoPayOrder: `${onePrefix}/shop/order/autoPay`,
  // 取消自动扣款
  cancelAutoPay: `${onePrefix}/shop/order/autoPay/cancel`,
  // 取消自动扣款V2
  cancelAutoPayV2: `${onePrefix}/shop/order/autoPay/cancelV2`,
  // 取消订单
  cancelOrder: `${onePrefix}/shop/order/cancel`,
  // 退款
  refundOrder: `${onePrefix}/aggregate/order/refund`,
  // 订单详情(旧的订单详情，支持轻付费及老三代订单)
  queryOrderDetail: `${onePrefix}/aggregate/order/detail`,
  // 订单结果
  queryOrderResult: `${onePrefix}/aggregate/order/result/v2`,
  queryOrderResultV2: `${onePrefix}/aggregate/order/result/v2`,
  // 子订单详情
  querySubOrderDetail: `${onePrefix}/shop/order/sub/detail/v2`,
  // 订单列表
  queryOrderList: `${onePrefix}/aggregate/order/list/v2`,
  // 获取家庭组订单列表
  queryOrderGroupList: `${onePrefix}/aggregate/order/list/v3`,
  // 刷新订单状态
  refreshOrderStatus: `${onePrefix}/shop/order/refreshOrderStatus`,
  // 获取alipay HK灰度用户名单
  getAlipayHKGrayList: `${onePrefix}/shop/order/pingPongGray`,
  // 更新订单地址
  updateOrderAddr: `${onePrefix}/shop/order/update/addr`,
  // 获取退款原因列表
  queryRefundReasonsList: `${onePrefix}/shop/order/refundReasons`,
  // 根据老师查在售课程列表
  queryTeacherClazz: `${onePrefix}/shop/goods/api/queryTeacherClazzV2`,
  // 课次包Id查询售卖skuId
  querySkuIdByPackageId: `${onePrefix}/shop/goods/api/getSkuIdByPackageId`,
  // 根据地址查询税费
  queryCategoryTax: `${onePrefix}/shop/goods/api/queryCategoryTax`,
  // 售卖子系统-待缴费清单相关

  // 配置信息
  getClientOptions: `${onePrefix}/config/clientOptions`,
  // 待缴费清单列表
  queryToBePaidList: `${onePrefix}/shop/goods/stu/toBePaidList`,
  // 待缴费清单列表 学生群组
  queryGroupToBePaidList: `${onePrefix}/shop/goods/stu/group/toBePaidList`,
  // 待缴费清单列表 学生群组V2
  queryGroupToBePaidListV2: `${onePrefix}/shop/goods/stu/group/toBePaidListV2`,
  // 续费指引
  unpaidRemind: `${onePrefix}/shop/goods/stu/guide-redDot`,
  // 续报配置信息
  getUnpaidInfo: `${onePrefix}/shop/goods/stu/cart/renewalInfo`,
  // 某课程可调换班级列表
  queryClassChangeListBySkuId: `${onePrefix}/shop/goods/stu/cart/changeList`,
  // 加购商品调换
  unpaidGoodschange: `${onePrefix}/shop/goods/stu/cart/change`,
  // 代报名表单信息
  queryFormData: `${onePrefix}/shop/goods/api/formdata`,
  // 订阅列表
  querySubscriptionList: `${onePrefix}/shop/subscription/list`,
  // 订阅详情
  querySubscriptionDetail: `${onePrefix}/shop/subscription/detail`,
  // 创建订阅
  createSubscription: `${onePrefix}/shop/subscription/pay`,
  // 取消订阅
  cancelSubscription: `${onePrefix}/aggregate/subscription/cancel`,
  // 取消订阅原因
  queryCancelReason: `${onePrefix}/shop/subscription/cancelReason`,
  // 配置数据
  // ----------------
  // 基础配置数据接口
  requeryBasicData: `${onePrefix}/config/init`,
  // 分校配置数据接口
  requerySchoolConfig: `${onePrefix}/config/allCampusWithConfigs`,
  // 获取配置信息
  getConfigInfo: `${onePrefix}/client/config/query`,
  // 优惠券相关
  // ----------------
  // 获取用户优惠券列表
  // queryUserCoupons: `${onePrefix}/coupon/api/userCoupons`,
  // 获取家庭组用户优惠券列表
  queryUserCoupons: `${onePrefix}/coupon/api/group/userCoupons`,
  // 根据优惠券id查适用班级id
  getClassIdsByCoupon: `${onePrefix}/coupon/middle/queryUseRange`,
  // 领取优惠券
  sendCoupon: `${onePrefix}/coupon/middle/sendCoupon`,
  // 获取当前商品可用优惠券&最佳优惠
  queryAvalibaleCoupons: `${onePrefix}/coupon/api/recommend`,
  // 查询家庭组有适用该班级券的学员列表
  studentList: `${onePrefix}/coupon/api/discount/studentList`,
  // 计算优惠价格
  calculateDiscountPrice: `${onePrefix}/coupon/api/checkOut`,
  // calculateDiscountPrice: `https://api-mock.talthinktech.com/mock/111/coupon/api/checkOut`,
  // 优惠券可用课程列表检索
  queryCouponClazz: `${onePrefix}/shop/goods/api/couponClazzSearch`,
  // 兑换优惠券
  redeemCoupon: `${onePrefix}/activity/api/redeemCoupon`,
  // 聚合接口
  // ----------------
  // 发送验证码
  sendOTP: `${onePrefix}/aggregate/otp/get`,
  // 诊断报名资格校验
  querySignUpCheck: `${onePrefix}/aggregate/signup/check`,
  // Signup校验v2 增加购课流程完善信息校验字段
  querySignUpCheckv2: `${onePrefix}/aggregate/signup/checkv2`,
  // 诊断接口
  queryEvaluation: `${onePrefix}/aggregate/evaluation/goto`,
  // 通过报告推荐课程
  queryRecommendCourse: `${onePrefix}/exam/api/testreport/scheduledRecommendCourse`,
  queryRecommendClass: `${onePrefix}/shop/goods/api/scheduledRecommendClass`,

  // ---------------

  // 调课转班
  // 订单是否显示调课转班入口
  checkChangByOrderId: `${onePrefix}/changeCenter/checkChangByOrderId`,
  // 跳转中心列表
  // changeCenterList: `${onePrefix}/changeCenter/changeCenterList`,
  changeCenterList: `${onePrefix}/changeCenter/family/changeCenterList`,

  // 待选择调课的讲次列表
  chooseChangeLessonList: `${onePrefix}/changeCenter/chooseChangeLessonList`,
  // 调转前置提示接口
  checkChangeStatus: `${onePrefix}/changeCenter/checkChangeStatus`,
  // 可调入讲次列表
  toBeChangeLessonList: `${onePrefix}/changeCenter/toBeChangeLessonList`,
  // 调课接口
  changeLesson: `${onePrefix}/changeCenter/changeLesson`,
  // // 转班接口
  // changeClass: `${onePrefix}/changeCenter/changeClass`,
  // 提交转班
  transferSubmitClass: `${onePrefix}/changeCenter/changeClass/v2`,
  // 获取差价转班结果
  transferGetFinal: `${onePrefix}/shop/order/changeClassQuery`,
  // 转班差价接口（判断是否有差价及差价详情）
  transferDetail: `${onePrefix}/changeCenter/calculate/difference`,
  // 可转入班级列表
  toBeChangeClassList: `${onePrefix}/changeCenter/toBeChangeClassList`,
  // 班级详情页
  changeSourceClass: `${onePrefix}/changeCenter/changeSourceClass`,
  // 讲次详情页
  changeSourceLesson: `${onePrefix}/changeCenter/changeSourceLesson`,
  // 提交线索
  addSaleslead: `${onePrefix}/saleslead/add`,
  // 提交自定义表单线索
  addCustomSaleslead: `${onePrefix}//saleslead/custom/add`,
  // 上课时间冲突校验
  checkTimeConflict: `${onePrefix}/changeCenter/checkTimeConflict`,
  // 购课线索
  enrollCheck: `${onePrefix}/crm/client/conversion/beforeCheckout`,
  // 新留资提交线索
  submitLeads: `${onePrefix}/crm/client/submitLeads`,
  // 获取预约日程信息
  queryCalendlyInfo: `${onePrefix}/crm/client/calendly/queryCalendlyInfo`,
  // 获取预约信息
  queryScheduledEventInfo: `${onePrefix}/crm/client/calendly/queryScheduledEventInfo`,
  // 诊断系统
  // 获取诊断报告列表
  queryReportList: `${onePrefix}/exam/api/auth/report/list`,
  // 获取诊断报告列表
  queryGroupReportList: `${onePrefix}/exam/api/auth/family/report/list`,
  // 获取最后一次诊断信息
  queryLatestTest: `${onePrefix}/exam/api/auth/getLatestTest`,
  // 诊断兑换码
  applyEvaluationRedeem: `${onePrefix}/exam/api/auth/redeem`,
  // 诊断兑换码适用班级
  qualifyCodeClazzSearch: `${onePrefix}/shop/goods/api/qualifyCodeClazzSearch`,

  // 获取预约诊断列表
  scheduledExam: `${onePrefix}/exam/api/auth/scheduledExam/list`,

  // 活动相关接口
  // 老带新-校验老生资格
  checkOld: `${onePrefix}/activity/api/invite/checkOld`,
  // 拼团活动相关接口
  checkGroupBuy: `${onePrefix}/activity/api/group/check`,
  // 参团详情接口
  queryGroupDetailByOrder: `${onePrefix}/aggregate/marketing/group`,
  // 活动信息
  shareInfo: `${onePrefix}/activity/api/share-info`,
  // 资源配置
  // 获取商城页面banner资源
  getCoursesBannerList: `${onePrefix}/manage/api/resource/query`,

  // 数据上报
  dataUpload: `${onePrefix}/data/upload`,
  // 获取频道数据
  getChannel: `${onePrefix}/manage/api/channel/list`,
  // 待缴费清单获取分期计划
  getOrderStage: `${onePrefix}/shop/order/stage/get`,
  // 待缴费清单获取分期计划v2
  getOrderStageV2: `${onePrefix}/shop/order/stage/getV2`,
  // 年级聚合页-概览信息
  getGoodsOverview: `${onePrefix}/shop/goods/api/goodsOverview`,
  // 年级聚合页-搜索信息
  postClassSearch: `${onePrefix}/shop/goods/api/clazzSearch/v2`,
  // 年级聚合页-采集信息
  intentionCollection: `${onePrefix}/saleslead/intentionCollection`,

  // 内容管理
  // 获取资源列表
  queryResourceByLocation: `${onePrefix}/manage/api/resource/query`,
  // c端下载资源
  getUrl: `${onePrefix}/manage/api/resource/download`,
  // 资源发送邮件
  resourcesSendEmail: `${onePrefix}/manage/api/resource/sendEmail`,

  // 配置化站点相关接口
  // 获取站点配置
  querySiteData: `${onePrefix}/website/api/site`,
  // 获取自适应商品登录状态
  queryStatus: `${onePrefix}/shop/goods/stu/getStudentServiceTime`,
  // 获取页面配置
  queryPageData: `${onePrefix}/website/api/page`,
  // 获取页面预览配置
  queryPagePreviewData: `${onePrefix}/website/api/page/preview`,
  // 获取网站页面组件模块
  queryPageModule: `${onePrefix}/website/api/module`,
  // 获取预览组件草稿
  queryModuleDraft: `${onePrefix}/website/api/getModuleDraft`,
  // 获取页面配置
  queryPageConfig: `${onePrefix}/website/api/getPageConfig`,

  // 物流系统
  // 查询物流包裹详情
  queryLogisticsPackage: `${onePrefix}/logistics/package/detail`,

  // 请求国际时区列表
  queryTimezoneList: `${onePrefix}/i18n/api/time/get/timezone`,

  // 录播课
  // 获取录播课列表
  getCourseList: `${onePrefix}/studyCenter/record/getCourseList`,
  getSchedule: `${onePrefix}/studyCenter/record/getSchedule`,
  getCurrentSchoolSignUpStatus: `${onePrefix}/studyCenter/signup/getCurrentSchoolSignUpStatus`,
  // 获取学员类型（上报神策埋点用）
  queryStudentType: `${onePrefix}/shop/order/queryStudentType`,

  // ChatGPT客服机器人
  startChatSession: `${oneAIPrefix}/webchat/start`,
  queryChatContent: `${oneAIPrefix}/webchat/ask`,
  getChatReply: `${oneAIPrefix}/webchat/reply`,

  venueList: `${onePrefix}/basic/client/venue/list`,
  filterInModule: `${onePrefix}/shop/goods/api/filterInModule`,
  getLsToken: `${onePrefix}/ucenter/account/access_token/get`,
  getTips: `${onePrefix}/exam/api/auth/tips`,
  getScheduleYy: `${onePrefix}/aggregate/exam/schedule`,
  // 成绩查询
  getScoreQuery: `${onePrefix}/website/api/queryScore`,
}

/**
  * 海外课堂服务接口
  */
export const overseaApi = {
  // 海外APP升级信息查询
  getUpgrade: `${overseaPrefix}/app-upgrade/get`,
  // 获取短链接源地址
  queryOriginURL: `${headlessPrefix}/queryOriginURL`,
  // 根据IP地址获取网络信息
  getCityInfoByIp: `${thirdPrefix}/inner/geo/getCityInfoByIp`,
}

export const zeroApi = {
  // 录播课视频上报
  watchRecorded: `${zeroPrefix}/inner/beibo/student/watch`,
}

export const CustomerService = {
  getSTST: '/one-upload-api/smartline/v1/getSTSToken',
  addUserMessage: '/customer/inner/addUserMessage',
  addUserMessageInClass: '/customer/inner/addOnlineTutoringUserMessage',
  getOptionsList: '/customer/inner/getMessageOptionsList',
  getLatestRobot: '/customer/inner/getRobot',
}
