import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _066376a6 = () => interopDefault(import('../../../src/client/pages/web/1-to-1-assessment/index.vue' /* webpackChunkName: "pages/web/1-to-1-assessment/index" */))
const _9077752c = () => interopDefault(import('../../../src/client/pages/web/about-think-academy/index.vue' /* webpackChunkName: "pages/web/about-think-academy/index" */))
const _aa83a282 = () => interopDefault(import('../../../src/client/pages/web/about-xes/index.vue' /* webpackChunkName: "pages/web/about-xes/index" */))
const _6fbb4e04 = () => interopDefault(import('../../../src/client/pages/web/aboutus/index.vue' /* webpackChunkName: "pages/web/aboutus/index" */))
const _6ec3911c = () => interopDefault(import('../../../src/client/pages/web/acceptable-use-policy/index.vue' /* webpackChunkName: "pages/web/acceptable-use-policy/index" */))
const _58f5f642 = () => interopDefault(import('../../../src/client/pages/web/assessment/index.vue' /* webpackChunkName: "pages/web/assessment/index" */))
const _62f3a8ca = () => interopDefault(import('../../../src/client/pages/web/career/index.vue' /* webpackChunkName: "pages/web/career/index" */))
const _e6d42520 = () => interopDefault(import('../../../src/client/pages/web/child-privacy/index.vue' /* webpackChunkName: "pages/web/child-privacy/index" */))
const _06feb390 = () => interopDefault(import('../../../src/client/pages/web/commerce/index.vue' /* webpackChunkName: "pages/web/commerce/index" */))
const _1d47e9cd = () => interopDefault(import('../../../src/client/pages/web/cookies-policy/index.vue' /* webpackChunkName: "pages/web/cookies-policy/index" */))
const _172d956a = () => interopDefault(import('../../../src/client/pages/web/course-material-fee-policy/index.vue' /* webpackChunkName: "pages/web/course-material-fee-policy/index" */))
const _dd35dade = () => interopDefault(import('../../../src/client/pages/web/courses/index.vue' /* webpackChunkName: "pages/web/courses/index" */))
const _b03fe0b6 = () => interopDefault(import('../../../src/client/pages/web/download/index.vue' /* webpackChunkName: "pages/web/download/index" */))
const _548aeb6f = () => interopDefault(import('../../../src/client/pages/web/faq/index.vue' /* webpackChunkName: "pages/web/faq/index" */))
const _56bd6788 = () => interopDefault(import('../../../src/client/pages/web/home/index.vue' /* webpackChunkName: "pages/web/home/index" */))
const _c80dcbb8 = () => interopDefault(import('../../../src/client/pages/web/internet-safety-tips/index.vue' /* webpackChunkName: "pages/web/internet-safety-tips/index" */))
const _6c6a2818 = () => interopDefault(import('../../../src/client/pages/web/level-test/index.vue' /* webpackChunkName: "pages/web/level-test/index" */))
const _30881192 = () => interopDefault(import('../../../src/client/pages/web/locations/index.vue' /* webpackChunkName: "pages/web/locations/index" */))
const _73a588a9 = () => interopDefault(import('../../../src/client/pages/web/my-account/index.vue' /* webpackChunkName: "pages/web/my-account/index" */))
const _f9d5f60c = () => interopDefault(import('../../../src/client/pages/web/my-class-transfer/index.vue' /* webpackChunkName: "pages/web/my-class-transfer/index" */))
const _21d733aa = () => interopDefault(import('../../../src/client/pages/web/pdf-viewer/index.vue' /* webpackChunkName: "pages/web/pdf-viewer/index" */))
const _35c0107c = () => interopDefault(import('../../../src/client/pages/web/personal-information/index.vue' /* webpackChunkName: "pages/web/personal-information/index" */))
const _402dc176 = () => interopDefault(import('../../../src/client/pages/web/pricing-policy/index.vue' /* webpackChunkName: "pages/web/pricing-policy/index" */))
const _cf54d7cc = () => interopDefault(import('../../../src/client/pages/web/privacy-notice/index.vue' /* webpackChunkName: "pages/web/privacy-notice/index" */))
const _232e0c18 = () => interopDefault(import('../../../src/client/pages/web/privacy-policy/index.vue' /* webpackChunkName: "pages/web/privacy-policy/index" */))
const _71cbc2c0 = () => interopDefault(import('../../../src/client/pages/web/refund-policy/index.vue' /* webpackChunkName: "pages/web/refund-policy/index" */))
const _43e3d13e = () => interopDefault(import('../../../src/client/pages/web/resources/index.vue' /* webpackChunkName: "pages/web/resources/index" */))
const _1b81084e = () => interopDefault(import('../../../src/client/pages/web/safeguarding-policy/index.vue' /* webpackChunkName: "pages/web/safeguarding-policy/index" */))
const _3570407e = () => interopDefault(import('../../../src/client/pages/web/shopping-cart/index.vue' /* webpackChunkName: "pages/web/shopping-cart/index" */))
const _30598e64 = () => interopDefault(import('../../../src/client/pages/web/short-term-course/index.vue' /* webpackChunkName: "pages/web/short-term-course/index" */))
const _6c281a5b = () => interopDefault(import('../../../src/client/pages/web/student-portal/index.vue' /* webpackChunkName: "pages/web/student-portal/index" */))
const _21fc1ea4 = () => interopDefault(import('../../../src/client/pages/web/teachers/index.vue' /* webpackChunkName: "pages/web/teachers/index" */))
const _3f1c62de = () => interopDefault(import('../../../src/client/pages/web/terms-and-conditions/index.vue' /* webpackChunkName: "pages/web/terms-and-conditions/index" */))
const _46074218 = () => interopDefault(import('../../../src/client/pages/web/terms-of-use/index.vue' /* webpackChunkName: "pages/web/terms-of-use/index" */))
const _6c13e105 = () => interopDefault(import('../../../src/client/pages/web/uk-tax-strategy/index.vue' /* webpackChunkName: "pages/web/uk-tax-strategy/index" */))
const _7b9fdc25 = () => interopDefault(import('../../../src/client/pages/web/user-agreement/index.vue' /* webpackChunkName: "pages/web/user-agreement/index" */))
const _de7fa714 = () => interopDefault(import('../../../src/client/pages/web/careers/job-listings/index.vue' /* webpackChunkName: "pages/web/careers/job-listings/index" */))
const _2570fa72 = () => interopDefault(import('../../../src/client/pages/web/courses/list/index.vue' /* webpackChunkName: "pages/web/courses/list/index" */))
const _afc176a0 = () => interopDefault(import('../../../src/client/pages/web/download/config.js' /* webpackChunkName: "pages/web/download/config" */))
const _11169566 = () => interopDefault(import('../../../src/client/pages/web/my-class-transfer/order-acknowledgement/index.vue' /* webpackChunkName: "pages/web/my-class-transfer/order-acknowledgement/index" */))
const _37908a9f = () => interopDefault(import('../../../src/client/pages/web/my-class-transfer/rules.vue' /* webpackChunkName: "pages/web/my-class-transfer/rules" */))
const _6b5284ce = () => interopDefault(import('../../../src/client/pages/web/my-coupons/history/index.vue' /* webpackChunkName: "pages/web/my-coupons/history/index" */))
const _6e43d47a = () => interopDefault(import('../../../src/client/pages/web/my-coupons/list/index.vue' /* webpackChunkName: "pages/web/my-coupons/list/index" */))
const _45e7526f = () => interopDefault(import('../../../src/client/pages/web/my-courses/list/index.vue' /* webpackChunkName: "pages/web/my-courses/list/index" */))
const _1ff5ebcc = () => interopDefault(import('../../../src/client/pages/web/my-subscription/list/index.vue' /* webpackChunkName: "pages/web/my-subscription/list/index" */))
const _2ec2e579 = () => interopDefault(import('../../../src/client/pages/web/my-unpaid-courses/list/index.vue' /* webpackChunkName: "pages/web/my-unpaid-courses/list/index" */))
const _64737ffc = () => interopDefault(import('../../../src/client/pages/web/my-wish/list/index.vue' /* webpackChunkName: "pages/web/my-wish/list/index" */))
const _6af4909c = () => interopDefault(import('../../../src/client/pages/web/order/list/index.vue' /* webpackChunkName: "pages/web/order/list/index" */))
const _b63b4612 = () => interopDefault(import('../../../src/client/pages/web/shopping-cart/ConfirmDelDialog.vue' /* webpackChunkName: "pages/web/shopping-cart/ConfirmDelDialog" */))
const _32b7512c = () => interopDefault(import('../../../src/client/pages/web/shopping-cart/OfferDetails.vue' /* webpackChunkName: "pages/web/shopping-cart/OfferDetails" */))
const _2015c326 = () => interopDefault(import('../../../src/client/pages/web/test/recommended-courses/index.vue' /* webpackChunkName: "pages/web/test/recommended-courses/index" */))
const _c253051e = () => interopDefault(import('../../../src/client/pages/web/test/test-results/index.vue' /* webpackChunkName: "pages/web/test/test-results/index" */))
const _debbac38 = () => interopDefault(import('../../../src/client/pages/web/order/shopping-cart/create/index.vue' /* webpackChunkName: "pages/web/order/shopping-cart/create/index" */))
const _656cce3c = () => interopDefault(import('../../../src/client/pages/web/activity/group/detail/_id.vue' /* webpackChunkName: "pages/web/activity/group/detail/_id" */))
const _36e0b06e = () => interopDefault(import('../../../src/client/pages/web/courses/detail/promote/_packageId.vue' /* webpackChunkName: "pages/web/courses/detail/promote/_packageId" */))
const _33a88471 = () => interopDefault(import('../../../src/client/pages/web/my-courses/recorded-courses/detail/_skuId.vue' /* webpackChunkName: "pages/web/my-courses/recorded-courses/detail/_skuId" */))
const _cd453b7a = () => interopDefault(import('../../../src/client/pages/web/my-courses/recorded-courses/video/_skuId.vue' /* webpackChunkName: "pages/web/my-courses/recorded-courses/video/_skuId" */))
const _327a8fef = () => interopDefault(import('../../../src/client/pages/web/order/detail/edit-address/_id.vue' /* webpackChunkName: "pages/web/order/detail/edit-address/_id" */))
const _7bc2b2a8 = () => interopDefault(import('../../../src/client/pages/web/order/detail/sub/_id.vue' /* webpackChunkName: "pages/web/order/detail/sub/_id" */))
const _cb246488 = () => interopDefault(import('../../../src/client/pages/web/courses/aggregational-detail/_pageId.vue' /* webpackChunkName: "pages/web/courses/aggregational-detail/_pageId" */))
const _3978a6c7 = () => interopDefault(import('../../../src/client/pages/web/courses/available-classes/_courseId.vue' /* webpackChunkName: "pages/web/courses/available-classes/_courseId" */))
const _121d7d31 = () => interopDefault(import('../../../src/client/pages/web/courses/detail/_coursesId.vue' /* webpackChunkName: "pages/web/courses/detail/_coursesId" */))
const _875b00ea = () => interopDefault(import('../../../src/client/pages/web/courses/recorded-detail/_skuId.vue' /* webpackChunkName: "pages/web/courses/recorded-detail/_skuId" */))
const _fa0bcd18 = () => interopDefault(import('../../../src/client/pages/web/goods/detail/_skuId.vue' /* webpackChunkName: "pages/web/goods/detail/_skuId" */))
const _494bb21c = () => interopDefault(import('../../../src/client/pages/web/my-account/edit-student/_id.vue' /* webpackChunkName: "pages/web/my-account/edit-student/_id" */))
const _2af08fab = () => interopDefault(import('../../../src/client/pages/web/my-class-transfer/class-transfer/_classId.vue' /* webpackChunkName: "pages/web/my-class-transfer/class-transfer/_classId" */))
const _07925152 = () => interopDefault(import('../../../src/client/pages/web/my-class-transfer/lesson-reschedule/_id.vue' /* webpackChunkName: "pages/web/my-class-transfer/lesson-reschedule/_id" */))
const _77d6e41b = () => interopDefault(import('../../../src/client/pages/web/my-coupons/detail/_couponId.vue' /* webpackChunkName: "pages/web/my-coupons/detail/_couponId" */))
const _59e1e294 = () => interopDefault(import('../../../src/client/pages/web/my-courses/detail/_classId.vue' /* webpackChunkName: "pages/web/my-courses/detail/_classId" */))
const _445fa8d4 = () => interopDefault(import('../../../src/client/pages/web/my-courses/prepare/_id.vue' /* webpackChunkName: "pages/web/my-courses/prepare/_id" */))
const _d6a301e6 = () => interopDefault(import('../../../src/client/pages/web/my-subscription/cancelSubscription/_id.vue' /* webpackChunkName: "pages/web/my-subscription/cancelSubscription/_id" */))
const _5815a5b2 = () => interopDefault(import('../../../src/client/pages/web/my-subscription/detail/_id.vue' /* webpackChunkName: "pages/web/my-subscription/detail/_id" */))
const _437f2766 = () => interopDefault(import('../../../src/client/pages/web/my-unpaid-courses/autopay-notice/_status.vue' /* webpackChunkName: "pages/web/my-unpaid-courses/autopay-notice/_status" */))
const _571c45c0 = () => interopDefault(import('../../../src/client/pages/web/my-unpaid-courses/class-transfer/_skuId.vue' /* webpackChunkName: "pages/web/my-unpaid-courses/class-transfer/_skuId" */))
const _3db42c3e = () => interopDefault(import('../../../src/client/pages/web/order/create/_skuId.vue' /* webpackChunkName: "pages/web/order/create/_skuId" */))
const _1ef3d1f7 = () => interopDefault(import('../../../src/client/pages/web/order/detail/_id.vue' /* webpackChunkName: "pages/web/order/detail/_id" */))
const _23f1097e = () => interopDefault(import('../../../src/client/pages/web/order/logistics/_orderId.vue' /* webpackChunkName: "pages/web/order/logistics/_orderId" */))
const _5af0fbc4 = () => interopDefault(import('../../../src/client/pages/web/order/refund/_id.vue' /* webpackChunkName: "pages/web/order/refund/_id" */))
const _b26b0cde = () => interopDefault(import('../../../src/client/pages/web/order/result/_orderId.vue' /* webpackChunkName: "pages/web/order/result/_orderId" */))
const _3fa23d99 = () => interopDefault(import('../../../src/client/pages/web/resources/list/_moduleId.vue' /* webpackChunkName: "pages/web/resources/list/_moduleId" */))
const _87bfb562 = () => interopDefault(import('../../../src/client/pages/web/settings/myaddresses/_id.vue' /* webpackChunkName: "pages/web/settings/myaddresses/_id" */))
const _03614bad = () => interopDefault(import('../../../src/client/pages/web/settings/security/_id.vue' /* webpackChunkName: "pages/web/settings/security/_id" */))
const _e4150f10 = () => interopDefault(import('../../../src/client/pages/web/teachers/detail/_teacherId.vue' /* webpackChunkName: "pages/web/teachers/detail/_teacherId" */))
const _750d6698 = () => interopDefault(import('../../../src/client/pages/web/test/exam-recommended-classes/_sessionId.vue' /* webpackChunkName: "pages/web/test/exam-recommended-classes/_sessionId" */))
const _5507d4fd = () => interopDefault(import('../../../src/client/pages/web/test/recommended-classes/_redeem.vue' /* webpackChunkName: "pages/web/test/recommended-classes/_redeem" */))
const _07a4d66b = () => interopDefault(import('../../../src/client/pages/web/j/_id.vue' /* webpackChunkName: "pages/web/j/_id" */))
const _f99710a8 = () => interopDefault(import('../../../src/client/pages/web/module-preview/_id.vue' /* webpackChunkName: "pages/web/module-preview/_id" */))
const _7f3bdfe8 = () => interopDefault(import('../../../src/client/pages/web/settings/_id.vue' /* webpackChunkName: "pages/web/settings/_id" */))
const _176be635 = () => interopDefault(import('../../../src/client/pages/web/_.vue' /* webpackChunkName: "pages/web/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/1-to-1-assessment",
    component: _066376a6,
    name: "1-to-1-assessment"
  }, {
    path: "/about-think-academy",
    component: _9077752c,
    name: "about-think-academy"
  }, {
    path: "/about-xes",
    component: _aa83a282,
    name: "about-xes"
  }, {
    path: "/aboutus",
    component: _6fbb4e04,
    name: "aboutus"
  }, {
    path: "/acceptable-use-policy",
    component: _6ec3911c,
    name: "acceptable-use-policy"
  }, {
    path: "/assessment",
    component: _58f5f642,
    name: "assessment"
  }, {
    path: "/career",
    component: _62f3a8ca,
    name: "career"
  }, {
    path: "/child-privacy",
    component: _e6d42520,
    name: "child-privacy"
  }, {
    path: "/commerce",
    component: _06feb390,
    name: "commerce"
  }, {
    path: "/cookies-policy",
    component: _1d47e9cd,
    name: "cookies-policy"
  }, {
    path: "/course-material-fee-policy",
    component: _172d956a,
    name: "course-material-fee-policy"
  }, {
    path: "/courses",
    component: _dd35dade,
    name: "courses"
  }, {
    path: "/download",
    component: _b03fe0b6,
    name: "download"
  }, {
    path: "/faq",
    component: _548aeb6f,
    name: "faq"
  }, {
    path: "/home",
    component: _56bd6788,
    name: "home"
  }, {
    path: "/internet-safety-tips",
    component: _c80dcbb8,
    name: "internet-safety-tips"
  }, {
    path: "/level-test",
    component: _6c6a2818,
    name: "level-test"
  }, {
    path: "/locations",
    component: _30881192,
    name: "locations"
  }, {
    path: "/my-account",
    component: _73a588a9,
    name: "my-account"
  }, {
    path: "/my-class-transfer",
    component: _f9d5f60c,
    name: "my-class-transfer"
  }, {
    path: "/pdf-viewer",
    component: _21d733aa,
    name: "pdf-viewer"
  }, {
    path: "/personal-information",
    component: _35c0107c,
    name: "personal-information"
  }, {
    path: "/pricing-policy",
    component: _402dc176,
    name: "pricing-policy"
  }, {
    path: "/privacy-notice",
    component: _cf54d7cc,
    name: "privacy-notice"
  }, {
    path: "/privacy-policy",
    component: _232e0c18,
    name: "privacy-policy"
  }, {
    path: "/refund-policy",
    component: _71cbc2c0,
    name: "refund-policy"
  }, {
    path: "/resources",
    component: _43e3d13e,
    name: "resources"
  }, {
    path: "/safeguarding-policy",
    component: _1b81084e,
    name: "safeguarding-policy"
  }, {
    path: "/shopping-cart",
    component: _3570407e,
    name: "shopping-cart"
  }, {
    path: "/short-term-course",
    component: _30598e64,
    name: "short-term-course"
  }, {
    path: "/student-portal",
    component: _6c281a5b,
    name: "student-portal"
  }, {
    path: "/teachers",
    component: _21fc1ea4,
    name: "teachers"
  }, {
    path: "/terms-and-conditions",
    component: _3f1c62de,
    name: "terms-and-conditions"
  }, {
    path: "/terms-of-use",
    component: _46074218,
    name: "terms-of-use"
  }, {
    path: "/uk-tax-strategy",
    component: _6c13e105,
    name: "uk-tax-strategy"
  }, {
    path: "/user-agreement",
    component: _7b9fdc25,
    name: "user-agreement"
  }, {
    path: "/careers/job-listings",
    component: _de7fa714,
    name: "careers-job-listings"
  }, {
    path: "/courses/list",
    component: _2570fa72,
    name: "courses-list"
  }, {
    path: "/download/config",
    component: _afc176a0,
    name: "download-config"
  }, {
    path: "/my-class-transfer/order-acknowledgement",
    component: _11169566,
    name: "my-class-transfer-order-acknowledgement"
  }, {
    path: "/my-class-transfer/rules",
    component: _37908a9f,
    name: "my-class-transfer-rules"
  }, {
    path: "/my-coupons/history",
    component: _6b5284ce,
    name: "my-coupons-history"
  }, {
    path: "/my-coupons/list",
    component: _6e43d47a,
    name: "my-coupons-list"
  }, {
    path: "/my-courses/list",
    component: _45e7526f,
    name: "my-courses-list"
  }, {
    path: "/my-subscription/list",
    component: _1ff5ebcc,
    name: "my-subscription-list"
  }, {
    path: "/my-unpaid-courses/list",
    component: _2ec2e579,
    name: "my-unpaid-courses-list"
  }, {
    path: "/my-wish/list",
    component: _64737ffc,
    name: "my-wish-list"
  }, {
    path: "/order/list",
    component: _6af4909c,
    name: "order-list"
  }, {
    path: "/shopping-cart/ConfirmDelDialog",
    component: _b63b4612,
    name: "shopping-cart-ConfirmDelDialog"
  }, {
    path: "/shopping-cart/OfferDetails",
    component: _32b7512c,
    name: "shopping-cart-OfferDetails"
  }, {
    path: "/test/recommended-courses",
    component: _2015c326,
    name: "test-recommended-courses"
  }, {
    path: "/test/test-results",
    component: _c253051e,
    name: "test-test-results"
  }, {
    path: "/order/shopping-cart/create",
    component: _debbac38,
    name: "order-shopping-cart-create"
  }, {
    path: "/activity/group/detail/:id?",
    component: _656cce3c,
    name: "activity-group-detail-id"
  }, {
    path: "/courses/detail/promote/:packageId?",
    component: _36e0b06e,
    name: "courses-detail-promote-packageId"
  }, {
    path: "/my-courses/recorded-courses/detail/:skuId?",
    component: _33a88471,
    name: "my-courses-recorded-courses-detail-skuId"
  }, {
    path: "/my-courses/recorded-courses/video/:skuId?",
    component: _cd453b7a,
    name: "my-courses-recorded-courses-video-skuId"
  }, {
    path: "/order/detail/edit-address/:id?",
    component: _327a8fef,
    name: "order-detail-edit-address-id"
  }, {
    path: "/order/detail/sub/:id?",
    component: _7bc2b2a8,
    name: "order-detail-sub-id"
  }, {
    path: "/courses/aggregational-detail/:pageId?",
    component: _cb246488,
    name: "courses-aggregational-detail-pageId"
  }, {
    path: "/courses/available-classes/:courseId?",
    component: _3978a6c7,
    name: "courses-available-classes-courseId"
  }, {
    path: "/courses/detail/:coursesId?",
    component: _121d7d31,
    name: "courses-detail-coursesId"
  }, {
    path: "/courses/recorded-detail/:skuId?",
    component: _875b00ea,
    name: "courses-recorded-detail-skuId"
  }, {
    path: "/goods/detail/:skuId?",
    component: _fa0bcd18,
    name: "goods-detail-skuId"
  }, {
    path: "/my-account/edit-student/:id",
    component: _494bb21c,
    name: "my-account-edit-student-id"
  }, {
    path: "/my-class-transfer/class-transfer/:classId?",
    component: _2af08fab,
    name: "my-class-transfer-class-transfer-classId"
  }, {
    path: "/my-class-transfer/lesson-reschedule/:id?",
    component: _07925152,
    name: "my-class-transfer-lesson-reschedule-id"
  }, {
    path: "/my-coupons/detail/:couponId?",
    component: _77d6e41b,
    name: "my-coupons-detail-couponId"
  }, {
    path: "/my-courses/detail/:classId?",
    component: _59e1e294,
    name: "my-courses-detail-classId"
  }, {
    path: "/my-courses/prepare/:id?",
    component: _445fa8d4,
    name: "my-courses-prepare-id"
  }, {
    path: "/my-subscription/cancelSubscription/:id?",
    component: _d6a301e6,
    name: "my-subscription-cancelSubscription-id"
  }, {
    path: "/my-subscription/detail/:id?",
    component: _5815a5b2,
    name: "my-subscription-detail-id"
  }, {
    path: "/my-unpaid-courses/autopay-notice/:status?",
    component: _437f2766,
    name: "my-unpaid-courses-autopay-notice-status"
  }, {
    path: "/my-unpaid-courses/class-transfer/:skuId?",
    component: _571c45c0,
    name: "my-unpaid-courses-class-transfer-skuId"
  }, {
    path: "/order/create/:skuId?",
    component: _3db42c3e,
    name: "order-create-skuId"
  }, {
    path: "/order/detail/:id?",
    component: _1ef3d1f7,
    name: "order-detail-id"
  }, {
    path: "/order/logistics/:orderId?",
    component: _23f1097e,
    name: "order-logistics-orderId"
  }, {
    path: "/order/refund/:id?",
    component: _5af0fbc4,
    name: "order-refund-id"
  }, {
    path: "/order/result/:orderId?",
    component: _b26b0cde,
    name: "order-result-orderId"
  }, {
    path: "/resources/list/:moduleId?",
    component: _3fa23d99,
    name: "resources-list-moduleId"
  }, {
    path: "/settings/myaddresses/:id?",
    component: _87bfb562,
    name: "settings-myaddresses-id"
  }, {
    path: "/settings/security/:id?",
    component: _03614bad,
    name: "settings-security-id"
  }, {
    path: "/teachers/detail/:teacherId?",
    component: _e4150f10,
    name: "teachers-detail-teacherId"
  }, {
    path: "/test/exam-recommended-classes/:sessionId?",
    component: _750d6698,
    name: "test-exam-recommended-classes-sessionId"
  }, {
    path: "/test/recommended-classes/:redeem?",
    component: _5507d4fd,
    name: "test-recommended-classes-redeem"
  }, {
    path: "/j/:id?",
    component: _07a4d66b,
    name: "j-id"
  }, {
    path: "/module-preview/:id?",
    component: _f99710a8,
    name: "module-preview-id"
  }, {
    path: "/settings/:id?",
    component: _7f3bdfe8,
    name: "settings-id"
  }, {
    path: "/*",
    component: _176be635,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
